// These method values match the function name in Express and should not be changed

export enum RequestMethod {
  "Get" = "get",
  "Put" = "put",
  "Post" = "post",
  "Patch" = "patch",
  "Delete" = "delete",
}

// TODO OCTO-916 this is just temporary until we migrate endpoints
// noinspection JSUnusedGlobalSymbols
export enum RequestMethodTsRest {
  "GET" = "GET",
  "POST" = "POST",
  "PATCH" = "PATCH",
  "DELETE" = "DELETE",
  /** Use PATCH instead: https://www.notion.so/gathertown/http-server-standards-748d5e2b891844fda302fd17d5cf8bff?pvs=4#e8083eb5003d4853bc47c13cfce1edc6 */
  "PUT" = "PUT",
}

export enum HttpV2Paths {
  /* Releases */
  BrowserClientReleases = "/releases/browser/:browserName/:releaseCommit/:releaseChannel",
  DesktopClientReleases = "/releases/desktop/:platform/:releaseVersion/:releaseChannel",
  DesktopClientLatest = "/releases/desktop/latest",

  /* Users */
  UserSpaces = "/users/:user/spaces",
  UserSpaceAccess = "/users/:user/spaces/:space/access",
  UserRecentSpaces = "/users/:user/recent-spaces",
  UserOwnedSpaces = "/users/:user/owned-spaces",
  UserDefaultWearables = "/users/available-wearables",
  UserWearables = "/users/:user/wearables",
  UserInitialOutfit = "/users/initial-outfit",
  User = "/users/:user",
  UserData = "/users/:user/data",
  UserDataField = "/users/:user/data/:field",
  UserMaps = "/users/:user/maps",
  UserAnnouncements = "/users/:user/spaces/:space/announcements",
  UserFiles = "/users/:userId/files",
  UserFile = "/users/:userId/files/:fileId",
  UserDevice = "/users/:user/devices/:deviceToken",

  /* Spaces */
  Spaces = "/spaces",
  Space = "/spaces/:space",
  SpaceUsers = "/spaces/:space/users",
  SpaceUser = "/spaces/:space/users/:user",
  SpaceUserCalendars = "/spaces/:space/users/:user/calendars",
  SpaceUserRoles = "/spaces/:space/users/:user/roles",
  SpaceUserOnboarding = "/spaces/:space/users/:user/onboarding",
  SpaceOnboardingChecklist = "/spaces/:space/users/me/onboarding-checklist",
  SpaceUserSettings = "/spaces/:space/users/:user/settings",
  SpaceUsersRequests = "/spaces/:space/membership-requests",
  SpaceUserRequest = "/spaces/:space/users/:user/membership-request",
  SpaceExtensions = "/spaces/:space/extensions",
  SpaceExtension = "/spaces/:space/extension/:extension",
  SpacePerfSurvey = "/spaces/:space/surveys/perf",
  SpaceOnboardingSurvey = "/spaces/:space/surveys/onboarding",
  SpaceReservations = "/spaces/:space/reservations",
  SpaceReservationInvoices = "/spaces/:space/reservation-invoices",
  SpaceReservation = "/spaces/:space/reservations/:reservationId",
  SpaceReservationSurveyResults = "/spaces/:space/reservations/:reservationId/surveys",
  SpaceOneTimeReservations = "/spaces/:space/one-time-reservations",
  SpaceOneTimeReservation = "/spaces/:space/one-time-reservations/:reservation",
  SpaceSubscriptions = "/spaces/:space/subscriptions",
  SpaceCustomerPaymentInfo = "/spaces/:space/customer/payment-info",
  SpaceCustomerPaymentLinks = "/spaces/:space/customer/payment-links",
  SpaceSubscriptionSurveys = "/spaces/:space/subscriptions/:subscription/surveys",
  SpaceReservationProratedPrice = "/spaces/:space/reservations/:reservationId/proratedPrice/:capacity",
  SpaceSubscriptionsInvoicePreview = "/spaces/:space/subscriptions/invoice-preview",
  SpacePlanIntervals = "/spaces/:space/plan-intervals",
  SpaceInvoice = "/spaces/:space/invoices/:invoiceId",
  SpaceCalendarAuths = "/spaces/:space/calendar-auths",
  SpaceCalendarAuth = "/spaces/:space/calendar-auths/:accountId",
  SpaceCalendarEvents = "/spaces/:space/calendar-events",
  UserRecurrentEvents = "/spaces/:space/recurrent-events",
  SpaceCalendarEvent = "/spaces/:space/calendar-events/:calendarId/:eventId",
  SpaceCalendar = "/spaces/:space/calendar",
  SpaceCapacityWarning = "/spaces/:space/capacity/warning",
  SpaceGameServerAssignment = "/spaces/:space/game-server-assignment",
  SpaceGameServerAssignmentPost = "/spaces/game-server-assignment",
  SpaceSettings = "/spaces/:space/settings",
  SpaceSpawns = "/spaces/:space/spawns",
  SpaceRoomSpawns = "/spaces/:space/:room/spawns",
  SpaceSpawnTokenItem = "/spaces/:space/spawn-tokens/:spawnTokenId",
  SpaceSpawnTokenDetails = "/spaces/:space/spawn-token-details/:spawnTokenId",
  SpaceSpawnToken = "/spaces/:space/spawn-tokens",
  SpaceNookSpawnTokens = "/spaces/:space/:room/nook-spawn-tokens",
  SpaceBannedIps = "/spaces/:space/banned-ips",
  SpaceBannedIp = "/spaces/:space/banned-ips/:ip",
  SpaceAnnouncements = "/spaces/:space/announcements",
  SpaceAnnouncement = "/spaces/:space/announcements/:announcementId",
  SpaceMaps = "/spaces/:space/maps",
  SpaceMap = "/spaces/:space/maps/:map",
  SpaceMapSize = "/spaces/:space/maps/:map/size",
  SpaceIntegrations = "/spaces/:space/integrations",
  SpaceObjectTemplates = "/spaces/:space/object-templates",
  SpacePasswordAttempt = "/spaces/:space/password-attempt",
  SpaceNooksSpawnTokens = "/spaces/:space/nooks",
  SpaceGuests = "/spaces/:space/guests",
  SpaceGuest = "/spaces/:space/guests/:guest",
  SpaceFile = "/spaces/:spaceId/files/:fileId",
  SpaceFiles = "/spaces/:spaceId/files",
  SpaceTrial = "/spaces/:space/trial",
  SpacePermissions = "/spaces/:space/permissions",
  SpaceReservationCustomer = "/spaces/:space/customer",
  SpaceReservationCustomerPaymentInfo = "/spaces/:space/customer/paymentInfo",
  SpaceReservationCustomerSetupIntents = "/spaces/:space/customer/setup-intents",
  SpaceSSO = "/spaces/:space/sso",
  SpaceSSOEmailDomains = "/spaces/:space/sso/email-domains",
  SpaceSSOSettings = "/spaces/:space/sso/settings",
  SpaceSSOLinkedSpaces = "/spaces/:space/sso/linked-spaces",
  SpaceSSOLinkedSpaceSource = "/spaces/:space/sso/linked-space-source",
  SpaceSSOEmailDomainVerification = "/spaces/:space/sso/email-domain/verify",
  SpaceRecordings = "/spaces/:space/recordings",
  SpaceRecording = "/spaces/:space/recordings/:recording",
  SpaceRecordingPreSignedURL = "/spaces/:space/recordings/:recording/pre-signed-url",
  SpaceRecordingBudget = "/spaces/:space/recording-budget",
  SpaceMeeting = "/spaces/:space/meetings/:eventId",
  SpaceTestEndpoint = "/spaces/:space/test-endpoint",

  /* Insights */
  SpaceInsights = "/spaces/:space/insights/:metric",
  SpaceInsightsMetricDetails = "/spaces/:space/insights-details/:metric",
  SpaceInsightsSummary = "/spaces/:space/insights-summary/:metrics",

  /* Map Templates */
  TemplateMap = "/templateMap/:template",
  TemplateMaps = "/templateMaps",

  /* Hubspot */
  HubSpotCreateContact = "/hubspot/contacts/create",
  HubSpotUpdateContact = "/hubspot/contacts/update",
  HubSpotUpdateContactNumberProperties = "/hubspot/contacts/update-number-properties",
  HubSpotSetContactPropertyOnce = "/hubspot/contacts/set-once/:property",

  /* Email Invites */
  /** @deprecated: Use EmailsInviteToSpace instead: https://linear.app/gather-town/issue/APP-5054/deprecate-invitefriend-endpoint-and-replace-with-createinvite */
  EmailsInviteFriend = "/emails/invite-friend",
  Invites = "/invites",

  /* Support Requests */
  SupportRequests = "/support-requests",

  /* OTP Auth */
  AuthOtpRequests = "/auth/otp-requests",
  AuthOtpRequestsVerify = "/auth/otp-requests/verify",

  /* SSO Auth */
  AuthSSORequests = "/auth/sso/initiate",
  AuthSSOTokenSwap = "/auth/sso/token-swap",
  AuthSSOUserIsMemberOfActiveOrganization = "/auth/sso/me",
  AuthSSOUserIsMemberOfActiveOrganizationByEmail = "/auth/sso/member",

  /* Google Auth */
  AuthGoogleTokenSwap = "/auth/google/token",
  AuthGoogleSignInAuthUrl = "/auth/google/authorize",

  /* Invite Tokens */
  InviteTokens = "/invite-tokens",
  InviteToken = "/invite-tokens/:inviteToken",

  /* Discount Codes */
  Discount = "/discounts/:discountCode",

  /* Wearables */
  Wearables = "/wearables",
  Wearable = "/wearables/:wearableId",

  /* User Ids */
  UserIds = "/user-ids",

  /* Gather Events */
  GatherEventsSpaceMaps = "/gather-events/space-maps/:spaceId",
  GatherEventsSpeakers = "/gather-events/speakers/:spaceId",
  GatherEventsSpeaker = "/gather-events/speakers/:spaceId/:speakerId",
  GatherEventsSessionsSchedule = "/gather-events/schedule/:spaceId/:eventId",
  GatherEventsSessionsForSpace = "/gather-events/sessions/:spaceId/",
  GatherEventsSessions = "/gather-events/sessions/:space/:eventId/",
  GatherEventsSession = "/gather-events/sessions/:spaceId/:eventId/:sessionId",
  GatherEventsACL = "/gather-events/:eventId/acl",
  GatherEventsACLMakeOwner = "/gather-events/:eventId/acl/owner",
  GatherEventsACLIsUserOwner = "/gather-events/:eventId/acl/is-user-owner",
  GatherEventsACLIsUserOwnerOrEditor = "/gather-events/:eventId/acl/is-user-owner-or-editor",
  GatherEventsACLDelete = "/gather-events/:eventId/acl/:email",
  GatherEventsPathwaysRooms = "/gather-events/pathways-rooms/:spaceId",
  GatherEventsUpdateSpeaker = "/gather-events/update-speaker",
  GatherEventsSyncRooms = "/gather-events/sync-rooms/:spaceId",
  Events = "/events",
  EventsDebugTriggerErrors = "/events/debug/errors",
  Event = "/events/:eventId",
  PublishedEvent = "/published-events/:eventId",
  GetMapInfoForSpace = "/get-map-info-for-space/:spaceId", // returns the default map
  GatherEventMapLocations = "/gather-events/map-locations/:spaceId",
  GatherEventMapLocation = "/gather-events/map-locations/:spaceId/location/:locationId",
  GatherEventsMessages = "/gather-events/messages/:spaceId",
  GatherEventsRoomMessages = "/gather-events/messages/:spaceId/:roomId",
  GatherEventsMessage = "/gather-events/messages/:spaceId/:messageId",

  /* Gather Admin */
  AdminAvClientStateSessions = "/admin/av-client-state/sessions",
  AdminAvClientStateSession = "/admin/av-client-state/sessions/:session",
  AdminAvClientStateUserFeedback = "/admin/av-client-state/sessions/:session/userFeedback",
  AdminDiscounts = "/admin/discounts",
  AdminDiscount = "/admin/discounts/:discount",
  AdminAuthUsers = "/admin/users",
  AdminAuthUser = "/admin/users/:emailOrId",
  AdminAuthUserDevices = "/admin/users/:user/devices",
  AdminAuthUserReservations = "/admin/users/:user/reservations",
  AdminAuthUserSendPushNotifications = "/admin/users/:user/notifications",
  AdminAuthUserSpaces = "/admin/users/:user/spaces",
  AdminObjectTemplates = "/admin/object-templates",
  AdminObjectTemplatesBulk = "/admin/object-templates/bulk",
  AdminObjectTemplate = "/admin/object-templates/:objectTemplate",
  AdminObjectTemplateVariants = "/admin/object-templates/:objectTemplate/variants",
  AdminObjectTemplateVariant = "/admin/object-templates/:objectTemplate/variants/:objectVariant",
  AdminObjectTemplateCategoryGroup = "/admin/object-templates/category-groups/:categoryGroup",
  AdminObjectTemplateCategoryGroups = "/admin/object-templates/category-groups",
  AdminReservation = "/admin/reservations/:reservation",
  AdminReservations = "/admin/reservations",
  AdminSpace = "/admin/spaces/:space",
  AdminSpaceTrial = "/admin/spaces/:space/trial",
  AdminSpaceCapacity = "/admin/spaces/:space/capacity",
  AdminSpaceMap = "/admin/spaces/:space/maps/:map",
  AdminSpaceMaps = "/admin/spaces/:space/maps",
  AdminSpaceReservations = "/admin/spaces/:space/reservations",
  AdminSpaceUserGrapes = "/admin/spaces/:space/users/:user/grapes",
  AdminSpaceUsers = "/admin/spaces/:space/users",
  AdminSpaceUserRecordings = "/admin/spaces/:space/users/:user/recordings",
  AdminSpaceUserRecording = "/admin/spaces/:space/users/:user/recordings/:recording",
  AdminUserRoles = "/admin/admin-users/:user/roles",
  AdminUsers = "/admin/admin-users",
  AdminUser = "/admin/admin-users/:adminUser",
  AdminSpaceMapTutorialAreas = "/admin/spaces/:space/maps/:map/tutorial-areas",
  AdminWearables = "/admin/wearables",
  AdminWearablePart = "/admin/wearable-part/:wearablePart",
  AdminWearable = "/admin/wearables/:wearable",
  AdminWearableImage = "/admin/wearable-image",

  /* Object Templates */
  ObjectTemplateCategoryGroups = "/object-templates/category-groups",

  /* Video */
  LivekitToken = "/video/livekit/:space",
  TwilioTurnCredentials = "/video/twilio-turn-credentials",

  /* Gather RW */
  RWPlayground = "/rw/spaces/:space/playground",
  RWOfficeConfigurationConfigure = "/rw/spaces/:space/office-configuration/configure",
  RWOfficeConfigurationRestoreBackup = "/rw/spaces/:space/office-configuration/restore",
  RWOfficeConfigurationDeleteBackup = "/rw/spaces/:space/office-configuration/delete/:backupId",
  RWOfficeConfigurationConfigureDefaultMapId = "/rw/spaces/:space/office-configuration/default-map-id/:source/:isBackup",
  // [START EXPERIMENT]: Ambient Desks / @emily-hu / End Date 2024-01-01
  RWAmbientSpaceTemplateDeskTemplate = "/rw/spaces/:space/maps/:map/desks/:desk/templateId",
  // [END EXPERIMENT]: Ambient Desks

  /* Gather RW Events */
  GrandPrixLeaderboard = "/rwe/spaces/:space/grand-prix-leaderboard",

  /* Slack */
  SpaceSlack = "/spaces/:space/integrations/slack",
  // Beware that changing this will require changing the values in the external service as well
  SlackCommand = "/integrations/slack/command",
  SlackInteract = "/integrations/slack/interact",

  /* Cloudflare */
  CloudflareSiteVerify = "/integrations/cloudflare/siteverify",

  /* Third Party Service Webhooks */
  WorkOSWebhook = "/webhooks/workos/8XIspKIObRYIHTDXVeCTuqEe", // WorkOS Webhook ObfuscatedUrl
  HookStripePayment = "/hooks/stripe/payment",
  HookAVRecordingS3 = "/hooks/recordings/s3",

  /* Scheduled Tasks */
  // Beware that changing this will require changing the values in the scheduler service as well
  ScheduledDeleteRecordings = "/scheduled-tasks/delete-recordings",

  /* Chrome Extension Logs */
  PublishLog = "/internal/logs",
  PublishMsOfficeAddinLog = "/internal/msoffice-addin/logs",

  /* NewRelic */
  NewRelicCustomEvents = "/newrelic/custom-events",

  /* Files */
  UploadImages = "/files/images",

  UserOutfitTemplates = "/outfits/templates",

  /* Zapier */
  ZapierRecordings = "/integrations/zapier/recordings",
}

// Beware that changing this will require changing the values in the external service as well
export enum HttpOAuthPaths {
  Slack = "/slack/callback",
  GoogleCalendar = "/google-calendar/callback",
  Microsoft = "/microsoft/callback",
  GoogleSignIn = "/signin/google/callback",
  SSOSignIn = "/signin/sso/callback",
}

// Sensible default used for `:user` when path params are not provided
export const DEFAULT_PATH_PARAM_USER = "me"; // nosemgrep
